import Content from "../Layout/Content";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

function Main() {
    return (
        <>
            <Header />
            <Content />
            <Footer />
        </>
    )
}
export default Main;