

const About = () => {
    return (
        <div id="about" className="component">
            <h2 className="title">About Us</h2>
            <div>
                <p>
                    Vision IT Global is a leading software training institute providing Software Training, Project Guidance,
                    IT
                    Consulting and Technology Workshops.
                </p>
                <p>
                    Using our enhanced global software training delivery methodology, innovative software training approach
                    and
                    industry expertise, we provide high-value corporate training services that enable our clients to enhance
                    business performance, accelerate time-to-market, increase productivity and improve customer service.</p>

                <p>
                    We serve Global 100 companies and the leading software vendors in Banking & Financial Services,
                    Insurance,
                    Telecommunications, Technology and Media, Information & Education industries. We design and mentor human
                    resources for our clients who create competitive advantage.
                </p>
            </div>
        </div>
    )
}
export default About;