import team1 from '../Assets/Team/team1.jpg';
import team2 from '../Assets/Team/team2.jpg';
import team3 from '../Assets/Team/team3.png';
import team4 from '../Assets/Team/team4.jpg';
import team5 from '../Assets/Team/team5.png';
import team6 from '../Assets/Team/team6.png';
import team7 from '../Assets/Team/team7.png';
import '../Styles/Team.css';

const Team = () => {
    return (
        <div id="team" className='component'>
            <h2 className="title">Team</h2>
            <div className="team">
                <div className='team-images'>
                    <div>
                        <img src={team1} className="team-image-size" alt="" />
                        <h4>Our Team</h4>
                    </div>
                    <div>
                        <img src={team2} className="team-image-size" alt="" />
                        <h4>Our Team</h4>
                    </div>
                </div>
                <div className='images'>
                    <div>
                        <img src={team3} className="team-individual-image-size" alt="" />
                        <h4>Technical Architect</h4>
                        <p>Naga Venkata</p>
                    </div>
                    <div>
                        <img src={team5} className="team-individual-image-size" alt="" />
                        <h4>Delivery Manager</h4>
                        <p>Sudhakar</p>
                    </div>
                    <div>
                        <img src={team6} className="team-individual-image-size" alt="" />
                        <h4>Team Lead</h4>
                        <p>Venkata Chary</p>
                    </div>
                    <div>
                        <img src={team4} className="team-individual-image-size" alt="" />
                        <h4>Senior Developer</h4>
                        <p>Krishna</p>
                    </div>
                    <div>
                        <img src={team7} className="team-individual-image-size" alt="" />
                        <h4>Ux Designer</h4>
                        <p>Hussain</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Team;


