import '../Styles/Footer.css';

function Footer() {
    return (
        <div className="Footer">
            <div>
                <h6>&copy; 2023</h6>
                <p>Phone Numbers : +91 7075834299 , +91 7075864199</p>
                <p>Email :  techteam@visionitglobal.com</p>
            </div>
        </div>
    )
}
export default Footer;