import '../../App.css'

const Contact = () => {
    return (
        <div id="contact" className="component">
            <h2 className="title">Contact Us</h2>
            <div className='google-maps'>
                <iframe
                    title="google-maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d951.1778517766328!2d78.31882663520945!3d17.52128449685076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb92a9ea347bd7%3A0xa34524508483bc81!2sMDITS%20%5B%20MISSION%20DRIVEN%20IT%20SOLUTIONS%20PRIVATE%20LIMITED%20%5D!5e0!3m2!1sen!2sin!4v1675406824696!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: "0" }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <div className='contact-us'>
                <div>
                    <h6>Phone Numbers</h6>
                    <p>+91 7075834299 , +91 7075864199</p>
                </div>
                <div>
                    <h6>Email</h6>
                    <p>techteam@visionitglobal.com</p>
                </div>
            </div>
        </div>
    )
}
export default Contact;


