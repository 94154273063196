import { Carousel } from 'react-bootstrap';
import Databse from '../Assets/slider_database.jpg';
import Devops from '../Assets/slider_devops.jpg';
import Java from '../Assets/slider_java.jpg';
import Javafullstackdeveloper from '../Assets/slider_javafullstackdeveloper.jpg';
import Uxdesign from '../Assets/slider_uxdesign.jpg';
import Webdevelopment from '../Assets/slider_webdevelopment.jpg';
import Python from '../Assets/slider_python.jpg';
import Mobiledevelopment from '../Assets/slider_mobiledevelopment.jpg';
import '../Styles/Content.css';
import Contact from '../Pages/Contact';
import Courses from '../Pages/Courses';
import About from '../Pages/About';
import Team from '../Pages/Team';

function Content() {
    return (
        <>
            <div className="Content">
                <div className="Carousel">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Javafullstackdeveloper}
                                alt="Javafullstackdeveloper" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Java}
                                alt="Java" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Webdevelopment}
                                alt="Webdevelopment" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Databse}
                                alt="Databse" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Devops}
                                alt="Devops" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Uxdesign}
                                alt="Uxdesign" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Mobiledevelopment}
                                alt="Mobiledevelopment" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Python}
                                alt="Python" />
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="Carousel">
                    <About />
                </div>
                <div className="Carousel">
                    <Courses />
                </div>
                <div className="Team">
                    <Team />
                </div>
                <div className="Carousel">
                    <Contact />
                </div>



            </div>
        </>
    )
}
export default Content;