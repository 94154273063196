import '../Styles/Header.css';
import { BsTelephone } from 'react-icons/bs'

function Header() {
    return (
        <div className="Header">
            <div>
                <a href="#home">Vision IT Global</a>
            </div>
            <span className='navbar-item-spacer-phone'></span>
            <div>
                <BsTelephone /> + 91 7075834299 , +91 7075864199
            </div>
            <span className='navbar-item-spacer'></span>
            <div>
                <ul className="HeaderList">
                    <li className="HeaderListItem"><a href="#about">ABOUT US</a></li>
                    <li className="HeaderListItem"><a href="#courses">COURSES</a></li>
                    <li className="HeaderListItem"><a href="#team">TEAM</a></li>
                    <li className="HeaderListItem"><a href="#contact">CONTACT US</a></li>
                </ul>
            </div>
        </div>
    )
}
export default Header;