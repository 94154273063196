import UxDesign from '../Assets/Courses/UxDesign.jpg';
import MobileApp from '../Assets/Courses/MobileApp1.jpg';
import AwsCloud from '../Assets/Courses/AwsCloud.jpg';
import AzureCloud from '../Assets/Courses/AzureCloud.png';
import DevOps from '../Assets/Courses/DevOps.png';
import Spring from '../Assets/Courses/spring.jpg';
import AngularJS from '../Assets/Courses/Angularjs.png';
import React from '../Assets/Courses/react.jpg';
import NodeJS from '../Assets/Courses/nodejs.png';
import MernStacktech from '../Assets/Courses/MernStacktech.png'
import MeanStack from '../Assets/Courses/MeanStack.png'
import WebDevelopment from '../Assets/Courses/WebDevelopment.jpg';
import BITools from '../Assets/Courses/BiTools.png';
import FullStack from '../Assets/Courses/FullStack.jpg';
import PythonDevelopment from '../Assets/Courses/PythonDevelopment.jpg';
import '../Styles/Courses.css';

const Courses = () => {
    return (
        <div id="courses" className="component">
            <h2 className="title">Courses</h2>
            <div className='service-card'>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={WebDevelopment} alt="" />
                        </header>
                        <h2 className="skill-card__title">Web Technology</h2>
                        <section className="skill-card__body">
                            <p style={{ marginBottom: '0' }}>Html</p>
                            <p style={{ marginBottom: '0' }}>Css</p>
                            <p style={{ marginBottom: '0' }}>Javascript</p>
                            <p style={{ marginBottom: '0' }}>Bootstrap</p>
                            <p style={{ marginBottom: '0' }}>Jquery</p>
                            <p style={{ marginBottom: '0' }}>Ajax</p>
                        </section>
                    </div>
                </div>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={MobileApp} alt="" />
                        </header>
                        <h2 className="skill-card__title">Mobile Development</h2>
                        <section className="skill-card__body">
                            <p style={{ marginBottom: '0' }}>React native</p>
                            <p style={{ marginBottom: '0' }}>Flutter</p>
                            <p style={{ marginBottom: '0' }}>Android</p>
                            <p style={{ marginBottom: '0' }}>IOS</p>
                        </section>
                    </div>
                </div>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={FullStack} alt="" />
                        </header>
                        <h2 className="skill-card__title">Java Full Stack Development</h2>
                        <section className="skill-card__body">
                            <p style={{ marginBottom: '0' }}>Core Java</p>
                            <p style={{ marginBottom: '0' }}>JDBC</p>
                            <p style={{ marginBottom: '0' }}>Hybernate</p>
                            <p style={{ marginBottom: '0' }}>Micro Services</p>
                            <p style={{ marginBottom: '0' }}>Spring</p>
                            <p style={{ marginBottom: '0' }}>Springboot</p>
                            <p style={{ marginBottom: '0' }}>Angular or React</p>
                            <p style={{ marginBottom: '0' }}>CICD</p>
                        </section>
                    </div>
                </div>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={MeanStack} alt="" />
                        </header>
                        <h2 className="skill-card__title">MEAN Stack</h2>
                        <section className="skill-card__body">
                            <p style={{ marginBottom: '0' }}>Html</p>
                            <p style={{ marginBottom: '0' }}>Css</p>
                            <p style={{ marginBottom: '0' }}>Javascript</p>
                            <p style={{ marginBottom: '0' }}>Express Js</p>
                            <p style={{ marginBottom: '0' }}>Angular</p>
                            <p style={{ marginBottom: '0' }}>Node Js</p>
                            <p style={{ marginBottom: '0' }}>Mongo DB</p>
                        </section>
                    </div>
                </div>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={MernStacktech} alt="" />
                        </header>
                        <h2 className="skill-card__title">MERN Stack</h2>
                        <section className="skill-card__body">
                            <p style={{ marginBottom: '0' }}>Html</p>
                            <p style={{ marginBottom: '0' }}>Css</p>
                            <p style={{ marginBottom: '0' }}>Javascript</p>
                            <p style={{ marginBottom: '0' }}>Express Js</p>
                            <p style={{ marginBottom: '0' }}>React</p>
                            <p style={{ marginBottom: '0' }}>Node Js</p>
                            <p style={{ marginBottom: '0' }}>Mongo DB</p>
                        </section>
                    </div>
                </div>
            </div>
            <div style={{ paddingTop: '10px' }}>
                <div className='service-card'>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={Spring} alt="" />
                            </header>
                            <h2 className="skill-card__title">Spring</h2>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={NodeJS} alt="" />
                            </header>
                            <h2 className="skill-card__title">Node JS</h2>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={AngularJS} alt="" />
                            </header>
                            <h2 className="skill-card__title">Angular</h2>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={React} alt="" />
                            </header>
                            <h2 className="skill-card__title">React JS</h2>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={PythonDevelopment} alt="" />
                            </header>
                            <h2 className="skill-card__title">Python Development</h2>
                            <section className="skill-card__body">
                                <p style={{ marginBottom: '0' }}>Core Python</p>
                                <p style={{ marginBottom: '0' }}>Django</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ paddingTop: '10px' }}>
                <div className='service-card'>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={UxDesign} alt="" />
                            </header>
                            <h2 className="skill-card__title">UI/UX Designing</h2>
                            <section className="skill-card__body">
                                <p style={{ marginBottom: '0' }}>Figma</p>
                                <p style={{ marginBottom: '0' }}>Adobe xd</p>
                                <p style={{ marginBottom: '0' }}>Photoshop</p>
                                <p style={{ marginBottom: '0' }}>Slack</p>
                            </section>
                        </div>
                    </div>

                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={DevOps} alt="" />
                            </header>
                            <h2 className="skill-card__title">Devops</h2>
                            <section className="skill-card__body">
                                <p style={{ marginBottom: '0' }}>Git</p>
                                <p style={{ marginBottom: '0' }}>Jenkins</p>
                                <p style={{ marginBottom: '0' }}>Ansible</p>
                                <p style={{ marginBottom: '0' }}>Terraform</p>
                                <p style={{ marginBottom: '0' }}>Docker</p>
                                <p style={{ marginBottom: '0' }}>AWS Devops</p>
                                <p style={{ marginBottom: '0' }}>Azure Devops</p>
                                <p style={{ marginBottom: '0' }}>etc...</p>
                            </section>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={AwsCloud} alt="" />
                            </header>
                            <h2 className="skill-card__title">AWS Cloud</h2>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={AzureCloud} alt="" />
                            </header>
                            <h2 className="skill-card__title">Azure Cloud</h2>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={BITools} alt="" />
                            </header>
                            <h2 className="skill-card__title">BI Tools</h2>
                            <section className="skill-card__body">
                                <p style={{ marginBottom: '0' }}>Power BI</p>
                                <p style={{ marginBottom: '0' }}>Snowflake</p>
                                <p style={{ marginBottom: '0' }}>Tableau</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Courses;
