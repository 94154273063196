import Main from './Components/Router/Main';
import { BrowserRouter as Router } from 'react-router-dom'

function App() {
  return (
    <div>
      <Router>
        <Main />
      </Router>
    </div>
  );
}

export default App;
